<template>
    <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Staff forms <strong>saved</strong> successfully.
    </v-alert>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
        icon="mdi-clipboard-edit-outline"
        title="WINDOWS AND DOORS SPEC SHEET"
        class="px-5 py-3">
            <v-container class="px-5 py-3">
                <v-row>
                
                <v-col
                    cols="12"
                    md="6"
                >
                    <v-select
                    label="BUILDER" :items="['BOULDER CONTRACTING', '']"
                    v-model="windows_and_doors_details.door_builder"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                    <v-text-field
                    label="JOB ADDRESS"
                    v-model="this.selected_user.Address"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="GARAGE LOCATIONS" :items="['LEFT','RIGHT', 'UNDECIDED']"
                    v-model="windows_and_doors_details.garage_location"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="WINDOW: BRAND" :items="['VECTOR', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_brand"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="WINDOW: COLOR" :items="['WHITE','ALMOND','CLAY','BLACK', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_color"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="WINDOW: GLASS" :items="['STANDARD LOE','INSULATED', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_glass"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="WINDOW: GRIDS" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_grids"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="PER PLAN" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_per_plan"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="LOCATION" :items="['FRONT OF HOUSE','ENTIRE HOUSE', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_location"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="TYPE" :items="['COLONIAL','PRAIRIE','CUSTOM', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_type"/>
                </v-col>

                <v-col cols="12" md="3">
                    <v-select label="WINDOW: JAMB KIT SPECIES" :items="['OAK','POPLAR', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_jamb_species"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="WINDOW: CASING PROFILE" :items="['RANCH-F113','FLAT-F156','PRINCETON-F118','MC5','OTHER', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_casing_profile"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field label="OTHER" v-model="windows_and_doors_details.window_casing_other"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="WINDOW: WALL SHEATING" :items="['1/2” = 6 9/16”','3/4” = 6 13/16”', 'UNDECIDED']"
                    v-model="windows_and_doors_details.window_wall_sheeting"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field label="WINDOW: JAMB THICKNESS" v-model="windows_and_doors_details.window_jamb_thickness"/>
                </v-col>
                <v-col cols="12"></v-col>

                <v-col cols="12" md="3">
                    <v-select label="DOORS: FRONT DOOR STYLE" :items="['EC300','Optional', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_frontdoor_style"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="DOORS: DEADLOCK" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_deadlock_front"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field label="DOORS: OPTIONAL" v-model="windows_and_doors_details.doors_optional"/>
                </v-col>

                <v-col cols="12" md="3"></v-col>
                
                <v-col cols="12" md="3">
                    <v-select label="DOORS: SIDELITE STYLE: STD" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_sidelite_style"/>
                </v-col>
                 <v-col cols="12" md="3">
                    <v-select label="DOORS: DOUBLE SIDELITE" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_double"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="DOORS: HOUSE TO GARAGE DOOR STYLE: EC300" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_house_to_garage_door_style"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="DOORS: DEADLOCK" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_deadlock_garage_service"/>
                </v-col>

                <v-col cols="12" md="3">
                    <v-select label="DOORS: GARAGE SERVICE DOOR STYLE: EC300" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_garage_service_door_style"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="DOORS: DEADLOCK" :items="['YES','NO', 'UNDECIDED']"
                    v-model="windows_and_doors_details.doors_deadlock_garage_style"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field label="DOORS: OTHER DOORS"
                    v-model="windows_and_doors_details.doors_other_doors"/>
                </v-col>
                
                <v-col cols="12" md="3"></v-col>

                <v-col cols="12" md="3">
                    <v-select label="ENTRY KNOBS" :items="['YES','NO','UNDECIDED', 'UNDECIDED']"
                    v-model="windows_and_doors_details.entry_knobs"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field label="ENTRY KNOBS: ENTRY DOOR KNOB STYLE"
                    v-model="windows_and_doors_details.entry_knobs_entry_door"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field label="ENTRY KNOBS: OTHER DOOR STYLE"
                    v-model="windows_and_doors_details.entry_knobs_other_door_style"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select label="KNOB FINISH" :items="['VENETIAN BRONZE','ANTIQUE NICKEL','POLISHED BRASS','SATIN NICKEL','ANTIQUE BRASS','POLISHED CHROME','BRUSHED CHROME', 'UNDECIDED']"
                    v-model="windows_and_doors_details.entry_knobs_knob_finish"/>
                </v-col>
                <v-col
                    cols="12"
                    class="text-right"
                >
                    <v-btn
                    color="success"
                    class="mr-0"
                    @click="updateWindowsAndDoorsDetails()"
                    >
                    Save
                    </v-btn>
                </v-col>
                <v-col>
                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </v-col>
                      </template>
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                             <v-expand-transition>
                          <v-card
                          v-if="add_vendor_email === 'Yes'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                            <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Vendor Email
                               </v-col>
                                <v-autocomplete
                                ref="user"
                                :items="vendor_email"
                                v-model="selected_vendor.Email"
                                item-text="Email"
                                label="Vendor Email"
                                prepend-icon="mdi-account-search"
                                outlined
                                solo
                                menu-props="auto"
                                class="px-3"
                                required
                                return-object
                                ></v-autocomplete>
                              </v-row>
                              </v-form>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionCompleteWindow"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                               <v-card
                          v-if="add_vendor_email === 'No'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                                 <v-card-actions>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                             </v-expand-transition>
                    </v-dialog>
                </v-col>
                </v-row>
            </v-container>
        </base-material-card>


        <base-material-card
        icon="mdi-clipboard-edit-outline"
        title="MILLWORK SPEC. SHEET"
        class="px-5 py-3">
            <v-container class="px-5 py-3">
                <v-row>
                    <v-col
                    cols="12"
                    md="6"
                    >
                        <v-select
                        label="BUILDER" :items="['BOULDER CONTRACTING', '']"
                        v-model="millwork_spec_sheet_details.mill_builder"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-text-field
                        label="JOB ADDRESS"
                        v-model="this.selected_user.Address"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="DOOR SPECIES" :items="['OAK','POPLAR', 'UNDECIDED']"
                        v-model="millwork_spec_sheet_details.mill_door_species"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="DOOR SPECIES: OTHER"
                        v-model="millwork_spec_sheet_details.mill_door_other"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="DOOR STYLE: STD 3 PANEL PRE FINISH WHITE"  :items="['YES','NO', 'UNDECIDED']"
                        v-model="millwork_spec_sheet_details.mill_door_style"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="PANTRY DOOR:" :items="['MATCH OTHER DOORS','STAINABLE', 'PAINT GRADE', 'UNDECIDED']"
                        v-model="millwork_spec_sheet_details.mill_is_stainible"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="FLEX RM. DOORS" :items="['MATCH OTHER DOORS','STAINABLE', 'PAINT GRADE', 'UNDECIDED']" 
                        v-model="millwork_spec_sheet_details.mill_flex_is_stainable"/>
                    </v-col>

                     <v-col cols="12" md="3">
                        <v-select label="FLEX RM. DOORS: GLASS" :items="['YES','NO',]"
                        v-model="millwork_spec_sheet_details.mill_flex_doors"/>
                    </v-col>
                     <v-col cols="12" md="3">
                        <v-select label="CABINET SPECIES" :items="['OAK','POPLAR', 'MAPLE', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_cabinet_species"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="JAMB & CASING SPECIES" :items="['OAK','POPLAR','MAPLE', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_jamb_casing"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="JAMB & CASING SPECIES:OTHER" v-model="millwork_spec_sheet_details.mill_jamb_casing_other"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="CASING STYLE: MAIN LEVEL" :items="['MC5','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_casing_style_ml"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="CASING STYLE: UPPER LEVEL" :items="['MC5','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_casing_style_ul"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="CASING STYLE: BASEMENT" :items="['MC5','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_casing_style_bl"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="BASE STYLE: MAIN LEVEL" :items="['RANCH-F206','MB21','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_base_style_ml"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="BASE STYLE: UPPER LEVEL" :items="['RANCH-F206','MB21','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_base_style_ul"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="BASE STYLE: BASEMENT" :items="['RANCH-F206','MB21','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_base_style_bl"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="WINDOW & DOOR TOPPERS: MAIN LEVEL" :items="['MC5','T500','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_door_toppers_style_ml"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="WINDOW & DOOR TOPPERS: UPPER LEVEL" :items="['MC5','T500','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_door_toppers_style_ul"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="WINDOW & DOOR TOPPERS: BASEMENT" :items="['MC5','T500','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_door_toppers_style_bl"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="WOOD RAILING" :items="['6010 (1 1/4”)','6210 (1 3/4”)', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_wood_railing"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="WOOD RAILING:OTHER" v-model="millwork_spec_sheet_details.mill_wood_railing_other"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="STAIR RAILING TO MATCH" :items="['YES','NO', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_stair_railing"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="WOOD CAP ON HALF WALLS" :items="['YES','NO', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_wood_cap_on_half_walls"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="SPECIES" :items="['OAK','POPLAR','MAPLE', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_species"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="SPECIES:OTHER" v-model="millwork_spec_sheet_details.mill_species_other"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="FINISH" :items="['SATIN BLACK','COPPER PATINA','SILVER VEIN','COPPER VEIN', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_finish"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="METAL SPINDLES" :items="['H0KNUC44','H1KNUC44','H2KNUCC44','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_metal_spindles"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="SPINDLES OTHER:" v-model="millwork_spec_sheet_details.mill_metal_spindles_other"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="NEWEL POST" :items="['963 POST','OTHER', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_newel"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="WOOD CLOSET SHELVING:  (MAIN LEVEL)" :items="['YES','NO', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_wood_closet"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-checkbox label="SKIRT BOARDS" v-model="millwork_spec_sheet_details.mill_skirt_boards"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-checkbox label="CASED OPENINGS" v-model="millwork_spec_sheet_details.mill_cased_openings"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select label="KNOB STYLE" :items="['POLO IS STANDARD', 'UNDECIDED']" v-model="millwork_spec_sheet_details.mill_knob_style"/>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="OTHER" v-model="millwork_spec_sheet_details.mill_knob_other"/>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select label="FINISH COLOR" :items="['VEN. BRONZE','ANTIQUE NICKEL','POLISHED BRASS','BRUSHED CHROME','SATIN NICKEL','ANTIQUE BRASS','POLISHED CHROME', 'UNDECIDED']"
                        v-model="millwork_spec_sheet_details.mill_finish_color"/>
                    </v-col>


                     <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0" 
                        @click="updateMillworkSpecSheet()">Save</v-btn>
                    </v-col>
                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </v-col>
                      </template>
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                             <v-expand-transition>
                          <v-card
                          v-if="add_vendor_email === 'Yes'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                            <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Vendor Email
                               </v-col>
                                <v-autocomplete
                                ref="user"
                                :items="vendor_email"
                                v-model="selected_vendor.Email"
                                item-text="Email"
                                label="Vendor Email"
                                prepend-icon="mdi-account-search"
                                outlined
                                solo
                                menu-props="auto"
                                class="px-3"
                                required
                                return-object
                                ></v-autocomplete>
                              </v-row>
                              </v-form>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionCompleteMillwork"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                               <v-card
                          v-if="add_vendor_email === 'No'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                                 <v-card-actions>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                             </v-expand-transition>
                    </v-dialog>
                </v-row>
            </v-container>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
    name:'StaffForms',
    data (){
        return {
            home_id: localStorage.getItem("homeid"),
            section_id: 'Staff Forms',
            alert:false,
            dialog: '',
            staff_email: 'steve@skelleymarketing.com',
            valid:'',
            builder:'Boulder Contracting',
            add_vendor_email:'',
            vendor_email: [],
            selected_vendor: {
                ItemId:0,
                Vendor: '',
                Name:'',
                Email: '',
                ModifiedDate:''
            },
            default_vendor: {
                ItemId:0,
                Vendor: '',
                Name:'',
                Email: '',
                ModifiedDate:''
            },
            windows_and_doors_details: {
                door_builder: "",
                door_job_address: "",
                garage_location: "",
                window_brand: "",
                window_color: "",
                window_glass: "",
                window_grids: "",
                window_per_plan: "",
                window_location: "",
                window_type: "",
                window_jamb_species: "",
                window_casing_profile: "",
                window_casing_other: "",
                window_wall_sheeting: "",
                window_jamb_thickness: "",
                doors_frontdoor_style: "",
                doors_deadlock_front: "",
                doors_optional: "",
                doors_sidelite_style: "",
                doors_double: "",
                doors_house_to_garage_door_style: "",
                doors_deadlock_garage_style: "",
                doors_garage_service_door_style: "",
                doors_deadlock_garage_service: "",
                doors_other_doors: "",
                entry_knobs: "",
                entry_knobs_entry_door: "",
                entry_knobs_other_door_style: "",
                entry_knobs_knob_finish: ""
            },
            millwork_spec_sheet_details:{
                    mill_builder:"",
                    mill_job_address:"",
                    mill_door_species:"",
                    mill_door_other:"",
                    mill_door_style:"",
                    mill_is_stainible:false,
                    mill_is_paint_grade:false,
                    mill_flex_is_stainable:false,
                    mill_flex_is_paint_grade:false,
                    mill_flex_doors:"",
                    mill_cabinet_species:"",
                    mill_jamb_casing:"",
                    mill_jamb_casing_other:"",
                    mill_casing_style_ml:false,
                    mill_casing_style_ul:false,
                    mill_casing_style_bl:false,
                    mill_base_style_ml:false,
                    mill_base_style_ul:false,
                    mill_base_style_bl:false,
                    mill_door_toppers_style_ml:false,
                    mill_door_toppers_style_ul:false,
                    mill_door_toppers_style_bl:false,
                    mill_stair_railing:"",
                    mill_wood_railing:"",
                    mill_wood_railing_other:"",
                    mill_wood_cap_on_half_walls:"",
                    mill_species:"",
                    mill_species_other:"",
                    mill_finish:"",
                    mill_metal_spindles:"",
                    mill_metal_spindles_other:"",
                    mill_newel:"",
                    mill_wood_closet:"",
                    mill_skirt_boards:false,
                    mill_cased_openings:false,
                    mill_knob_style:"",
                    mill_knob_other:"",
                    mill_finish_color:"",
            },
            selected_user: {
            PrimaryEmailAddress:'',
            SecondaryEmailAddress:'',
            PrimaryFirstName: '',
            PrimaryLastName: '',
            SecondaryFirstName: '',
            SecondaryLastName: '',
            Address:'',
            City:'',
            State: '',
            Zip: '',
            BuildStart: '',
            BuildEnd:'',
            CellNumber:'',
            HomeNumber: '',
            WorkPhone:'',
            Role:'',
            Agent:''
		},
        }
    },
    created (){
        this.getVendorEmail()
        this.getUserInfo()
        this.getWindowsAndDoorsDetails()
        this.getMillworkSpecSheet()
    },
    methods:{
        getWindowsAndDoorsDetails(){
            axios
            .get(process.env.VUE_APP_GET_WIN_AND_DOORS_DETAILS_URL,
            {
            params: {
                home_id: this.home_id
            }
            })
            .then(response => {
                const { data } = response
                if (data) {
                    this.windows_and_doors_details = JSON.parse(data['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getMillworkSpecSheet(){
            axios
            .get(process.env.VUE_APP_GET_MILL_WORK_DETAILS_URL,
            {
            params: {
                home_id: this.home_id
            }
            })
            .then(response => {
                
                if (response.data)
                {
                    var result = response.data
                    this.millwork_spec_sheet_details = JSON.parse(result['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        updateWindowsAndDoorsDetails(){
            var body = {
            'home_id':this.home_id,
            "windows_and_doors_details_text":JSON.stringify(this.windows_and_doors_details),
            }   

            axios
            .post(process.env.VUE_APP_UPDATE_WIN_AND_DOORS_DETAILS_URL,body)
            .then(() => {
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
    updateMillworkSpecSheet(){
        var body = {
        'home_id':this.home_id,
        "millwork_spec_sheet_details_text":JSON.stringify(this.millwork_spec_sheet_details),
        }     

        axios
        .post(process.env.VUE_APP_UPDATE_MILL_WORK_DETAILS_URL,body)
        .then(() => {
            this.alert = true
        })
        .catch(error => {
        console.log(error)
        })
    },
    getSection(){
        axios
        .get(process.env.VUE_APP_GET_SECTION_TWO_URL,
        {
        params: {
            home_id: this.home_id,
            section_id: this.section_id
        }
        })
        .then(response => {
        if (response.data) {
            var result = response.data
            this.section = JSON.parse(result['JsonDetails'])
        }
        })
        .catch(error => {
        console.log(error)
        })
    },
    getUserInfo(){
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
    },
    getVendorEmail (){
          axios
          .get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
          .then(response => {
            const {data} = response
            this.vendor_email = data.filter((element) => element.Vendor === 'North Metro Supplies' || element.Vendor === 'Boulder Contracting' || element.Vendor === 'Skelley Marketing')
          })
          .catch(error => {
            console.log(error)
          })
    },
    sectionCompleteWindow(){
        const theNewContent = {
        homeOwner: {
          ...this.selected_user
        },
        sectionData: [
                {
                    Title:"BUILDER",
                    Value: this.windows_and_doors_details.door_builder
                },
                {
                    Title:"JOB ADDRESS",
                    Value: this.selected_user.Address
                },
                {
                    Title:"GARAGE LOCATIONS",
                    Value: this.windows_and_doors_details.garage_location
                },
                { 
                    Title:"WINDOW - BRAND",
                    Value: this.windows_and_doors_details.window_brand
                },
                { 
                    Title:"WINDOW - COLOR",
                    Value: this.windows_and_doors_details.window_color
                },
                { 
                    Title:"WINDOW - GLASS",
                    Value: this.windows_and_doors_details.window_glass
                },
                { 
                    Title:"WINDOW - GRIDS",
                    Value: this.windows_and_doors_details.window_grids
                },
                { 
                    Title:"PER PLAN",
                    Value: this.windows_and_doors_details.window_per_plan
                },
                { 
                    Title:"LOCATION",
                    Value: this.windows_and_doors_details.window_location
                },
                { 
                    Title:"TYPE",
                    Value: this.windows_and_doors_details.window_type
                },
                { 
                    Title:"WINDOW - JAMB KIT SPECIES",
                    Value: this.windows_and_doors_details.window_jamb_species
                },
                { 
                    Title:"WINDOW - CASING PROFILE",
                    Value: this.windows_and_doors_details.window_casing_profile
                },
                { 
                    Title:"OTHER",
                    Value: this.windows_and_doors_details.window_casing_other
                },
                { 
                    Title:"WINDOW - WALL SHEATING",
                    Value: this.windows_and_doors_details.window_wall_sheeting
                },
                { 
                    Title:"WINDOW - JAMB THICKNESS",
                    Value: this.windows_and_doors_details.window_jamb_thickness
                },
                { 
                    Title:"DOORS - FRONT DOOR STYLE",
                    Value: this.windows_and_doors_details.doors_frontdoor_style
                },
                { 
                    Title:"DOORS - DEADLOCK FRONT",
                    Value: this.windows_and_doors_details.doors_deadlock_front
                },
                { 
                    Title:"DOORS - OPTIONAL",
                    Value: this.windows_and_doors_details.doors_optional
                },
                { 
                    Title:"DOORS - SIDELITE STYLE: STD",
                    Value: this.windows_and_doors_details.doors_sidelite_style
                },
                { 
                    Title:"DOORS - DOUBLE SIDELITE",
                    Value: this.windows_and_doors_details.doors_double
                },
                { 
                    Title:"DOORS - HOUSE TO GARAGE DOOR STYLE - EC300",
                    Value: this.windows_and_doors_details.doors_house_to_garage_door_style
                },
                { 
                    Title:"DOORS - DEADLOCK",
                    Value: this.windows_and_doors_details.doors_deadlock_garage_service
                },
                { 
                    Title:"DOORS - GARAGE SERVICE DOOR STYLE - EC300",
                    Value: this.windows_and_doors_details.doors_garage_service_door_style
                },
                { 
                    Title:"DOORS - DEADLOCK",
                    Value: this.windows_and_doors_details.doors_deadlock_garage_style
                },
                { 
                    Title:"DOORS - OTHER DOORS",
                    Value: this.windows_and_doors_details.doors_other_doors
                },
                { 
                    Title:"ENTRY KNOBS",
                    Value: this.windows_and_doors_details.entry_knobs
                },
                { 
                    Title:"ENTRY KNOBS - ENTRY DOOR KNOB STYLE",
                    Value: this.windows_and_doors_details.entry_knobs_entry_door
                },
                { 
                    Title:"ENTRY KNOBS - OTHER DOOR STYLE",
                    Value: this.windows_and_doors_details.entry_knobs_other_door_style
                },
                { 
                    Title:"KNOB FINISH",
                    Value: this.windows_and_doors_details.entry_knobs_knob_finish
                }
        ]
        ,
        vendorData: {
          ...this.selected_vendor
        }
        }
        this.alert = true
        if (this.add_vendor_email[0] === 'Y')
        this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",theNewContent, this.section_id)
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
    },
    sectionCompleteMillwork(){
        const theNewContent = {
        homeOwner: {
          ...this.selected_user
        },
        sectionData:[
            { 
              Title:"BUILDER",
              Value: this.millwork_spec_sheet_details.mill_builder
          },
          { 
              Title:"JOB ADDRESS",
              Value: this.selected_user.Address
          },
          { 
              Title:"DOOR SPECIES",
              Value: this.millwork_spec_sheet_details.mill_door_species
          },
          { 
              Title:"DOOR SPECIES - OTHER",
              Value: this.millwork_spec_sheet_details.mill_door_other
          },
          { 
              Title:"DOOR STYLE - STD 3 PANEL PRE FINISH WHITE",
              Value: this.millwork_spec_sheet_details.mill_door_style
          },
          { 
              Title:"PANTRY DOOR",
              Value: this.millwork_spec_sheet_details.mill_is_stainible
          },
          { 
              Title:"FLEX RM. DOORS",
              Value: this.millwork_spec_sheet_details.mill_flex_is_stainable
          },
          { 
              Title:"FLEX RM. DOORS - GLASS",
              Value: this.millwork_spec_sheet_details.mill_flex_doors
          },
          { 
              Title:"CABINET SPECIES",
              Value: this.millwork_spec_sheet_details.mill_cabinet_species
          },
          { 
              Title:"JAMB & CASING SPECIES",
              Value: this.millwork_spec_sheet_details.mill_jamb_casing
          },
          { 
              Title:"JAMB & CASING SPECIES - OTHER",
              Value: this.millwork_spec_sheet_details.mill_jamb_casing_other
          },
          { 
              Title:"CASING STYLE - MAIN LEVEL",
              Value: this.millwork_spec_sheet_details.mill_casing_style_ml
          },
          { 
              Title:"CASING STYLE - UPPER LEVEL",
              Value: this.millwork_spec_sheet_details.mill_casing_style_ul
          },
          { 
              Title:"CASING STYLE - BASEMENT",
              Value: this.millwork_spec_sheet_details.mill_casing_style_bl
          },
          { 
              Title:"BASE STYLE - MAIN LEVEL",
              Value: this.millwork_spec_sheet_details.mill_base_style_ml
          },
          { 
              Title:"BASE STYLE - UPPER LEVEL",
              Value: this.millwork_spec_sheet_details.mill_base_style_ul
          },
          { 
              Title:"BASE STYLE - BASEMENT",
              Value: this.millwork_spec_sheet_details.mill_base_style_bl
          },
          { 
              Title:"WINDOW & DOOR TOPPERS - MAIN LEVEL",
              Value: this.millwork_spec_sheet_details.mill_door_toppers_style_ml
          },
          { 
              Title:"WINDOW & DOOR TOPPERS - UPPER LEVEL",
              Value: this.millwork_spec_sheet_details.mill_door_toppers_style_ul
          },
          { 
              Title:"WINDOW & DOOR TOPPERS - BASEMENT",
              Value: this.millwork_spec_sheet_details.mill_door_toppers_style_bl
          },
          { 
              Title:"WOOD RAILING",
              Value: this.millwork_spec_sheet_details.mill_wood_railing
          },
          { 
              Title:"WOOD RAILING:OTHER",
              Value: this.millwork_spec_sheet_details.mill_wood_railing_other
          },
          { 
              Title:"STAIR RAILING TO MATCH",
              Value: this.millwork_spec_sheet_details.mill_stair_railing
          },
          { 
              Title:"WOOD CAP ON HALF WALLS",
              Value: this.millwork_spec_sheet_details.mill_wood_cap_on_half_walls
          },
          { 
              Title:"SPECIES",
              Value: this.millwork_spec_sheet_details.mill_species
          },
          { 
              Title:"SPECIES - OTHER",
              Value: this.millwork_spec_sheet_details.mill_species_other
          },
          { 
              Title:"FINISH",
              Value: this.millwork_spec_sheet_details.mill_finish
          },
          { 
              Title:"METAL SPINDLES",
              Value: this.millwork_spec_sheet_details.mill_metal_spindles
          },
          { 
              Title:"SPINDLES OTHER",
              Value: this.millwork_spec_sheet_details.mill_metal_spindles_other
          },
          { 
              Title:"NEWEL POST",
              Value: this.millwork_spec_sheet_details.mill_newel
          },
          { 
              Title:"WOOD CLOSET SHELVING - (MAIN LEVEL)",
              Value: this.millwork_spec_sheet_details.mill_wood_closet
          },
          { 
              Title:"SKIRT BOARDS",
              Value: this.millwork_spec_sheet_details.mill_skirt_boards
          },
          { 
              Title:"CASED OPENINGS",
              Value: this.millwork_spec_sheet_details.mill_cased_openings
          },
          { 
              Title:"KNOB STYLE",
              Value: this.millwork_spec_sheet_details.mill_knob_style
          },
          { 
              Title:"OTHER",
              Value: this.millwork_spec_sheet_details.mill_knob_other
          },
          { 
              Title:"FINISH COLOR",
              Value: this.millwork_spec_sheet_details.mill_finish_color
          },
        ],
        vendorData: {
          ...this.selected_vendor
        }
        }

        this.alert = true
        if (this.add_vendor_email[0] === 'Y')
        this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",theNewContent, this.section_id)
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
    },
    close(){
        this.dialog = false
        this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
        })
    },
    sendEmail(to,from,template,content,section){
        let email_body = {
            "to": to,
            "from": from,
            "template_name": template,
            "template_data": {
                "content": content,
                "section": section
            }
        }

    axios
    .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
    .then(() => {
    })
    .catch(error => {
    console.log(error)
    })

    }
    }
}
</script>